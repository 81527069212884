
import { defineComponent } from 'vue'

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "Orders",
    data(){
        return{
            token: '',
            loading: false,
            status: {
                pending: {
                    translation: 'A Pagar',
                    color: '#607d8b'
                },
                paid: {
                    translation: 'Pago',
                    color: '#4caf50'
                },
                printing: {
                    translation: 'Imprimiendo',
                    color: '#ff9800'
                },
                'on-way': {
                    translation: 'En Camino',
                    color: '#673ab7'
                },
                unknown: {
                    translation: 'Desconocido',
                    color: '#f44336'
                },
                delivered: {
                    translation: 'Entregado',
                    color: '#2196f3'
                }
            },
            orders: []
        }
    },

    mounted(){
        this.loading = true
        //check auth and get token
        let checkAuth = utilities.checkAuth('mi-cuenta') as any
        Promise.resolve(checkAuth).then((data) => {
            this.token = data.token as any

            //get orders
            let orders= utilities.getItems('orders?fields=*,shipping_method.*,items.*&sort=-created_on', this.token) as any
            Promise.resolve(orders).then((data: any[any]) => {
                this.orders = data
                
                /*this.orders.forEach( (order:any[any], index:number) =>{
                    console.log(index)
                    if(order.status == 'pending'){
                        (this.orders[index] as string) = 'pendiente'
                    }
                })*/

                this.loading = false
            })
        })
    },

    computed: {
        orderlist: function (){
            return this.orders;
        }
    }
})
